import urls from '@/constants/endpoints/gateway';
import {
    AppointmentRequestDetailModel,
    AppointmentVisitTypesDTO,
    IdResponseDTO,
    InHomeAppointmentReferralRequestDTO,
    LastAppointmentRequestDTO,
    ReferralDateRequestDTO,
    ReferralDatesRequestDTO,
    SendAppointmentRequestDTO,
    SimpleMessageResponseDTO,
    SPIRequestMetaDTO,
    SPIStatusResponseDTO,
} from '@/types/gatewayDataModels';

import httpApi from './httpApi_new';

export type ChangeVendorReferralDate = ({
    actorId,
    value,
}: {
    actorId: number;
    value: string;
}) => Promise<SimpleMessageResponseDTO>;

class SPIService {
    sendSPI = (data: SPIRequestMetaDTO): Promise<SPIStatusResponseDTO> =>
        httpApi.post({
            url: urls.SEND_SPI,
            data,
        });

    changeVendorReferralAcceptedDate: ChangeVendorReferralDate = ({actorId, value}) => {
        const data: ReferralDateRequestDTO = {dateTime: value};
        return httpApi.patch({
            url: urls.CHANGE_VENDOR_REFERRAL_ACCEPTED_DATE(actorId),
            data,
        });
    };

    changeVendorReferralSentDate: ChangeVendorReferralDate = ({actorId, value}) => {
        const data: ReferralDateRequestDTO = {dateTime: value};
        return httpApi.patch({
            url: urls.CHANGE_VENDOR_REFERRAL_SENT_DATE(actorId),
            data,
        });
    };

    changeVendorReferralDates = ({
        actorId,
        sentDateTime,
        acceptedDateTime,
    }: ReferralDatesRequestDTO & {actorId: number}): Promise<SimpleMessageResponseDTO> =>
        httpApi.patch({
            url: urls.CHANGE_VENDOR_REFERRAL_DATES(actorId),
            data: {sentDateTime, acceptedDateTime},
        });

    changeAppointmentReferralDateTime = ({
        id,
        referrals,
    }: {
        id: number;
        referrals: InHomeAppointmentReferralRequestDTO;
    }): Promise<IdResponseDTO> => httpApi.put({url: urls.CHANGE_APPOINTMENT_REFERRAL_DATES(id), data: referrals});

    sendAppointmentRequest = (data: SendAppointmentRequestDTO) =>
        httpApi.post({url: urls.SEND_APPOINTMENT_REQUEST, data});

    getAppointmentVisitTypes = (): Promise<AppointmentVisitTypesDTO> =>
        httpApi.get({url: urls.APPOINTMENT_VISIT_TYPES});

    getGMRHistoryData = (id: number): Promise<LastAppointmentRequestDTO> =>
        httpApi.get({url: urls.LAST_APPOINTMENT_REQUEST(id)});

    getAppointmentRequestDetails = (id: number): Promise<AppointmentRequestDetailModel> =>
        httpApi.get({url: urls.APPOINTMENT_REQUEST_DETAILS(id)});
}

const spiService = new SPIService();
export default spiService;
