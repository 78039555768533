import * as commonDataNames from '@/constants/commonDataNames';
import {CommonData} from '@/types/commonData';

import appointmentCancelReasonsService from '../appointmentsService';
import deviceService from '../deviceService';
import generalInfoService from '../generalInfoService';
import serviceRequestCancelReasonsService from '../handOffService';
import kitService from '../kitService';
import missionControlConfigurationService from '../missionControlConfigurationService';
import {observationService} from '../observationService';
import ordersService from '../orderService';
import patientService from '../patientService';
import performanceImprovementLogService from '../performanceImprovementLogService';
import spiService from '../spiService';
import taskTemplatesService from '../taskTemplatesService';
import vendorDictionaryService from '../vendorDictionaryService';
import vendorsService from '../vendorsService';

const {
    getImprovementLogEventTypes,
    getCausalFactors,
    getResponsibleActors,
    getSubmissionTypes,
    getLevelsOfInvestigation,
    getClosedPerformanceImprovementLogReporters,
    getInReviewPerformanceImprovementLogReporters,
    getNewPerformanceImprovementLogReporters,
} = performanceImprovementLogService;
const {
    getPayors,
    getProducts,
    getSponsors,
    getPods,
    getTimeZones,
    getStates,
    getMissionControls,
    getIncidentsStatuses,
    getIsoLanguages,
    getGenders,
    getGenderIdentities,
    getClusters,
    getNurseProfiles,
    getNurseRoles,
    getLocal911Types,
    getAdditionalLocalEmergencyTypes,
    getHandOffStatuses,
    getHandOffUrgencies,
    getHandOffCancellationStatuses,
    getHandOffCancellationUrgencies,
    getEhrs,
    getThresholdTypes,
    getRelations,
    getMessageTypes,
    getPhaseOfCare,
    getNurseOptions,
    getGeneralAppointmentTypes,
    getSelfCheckInOptions,
    getSelfCheckOutOptions,
    getActivityRefuseReasonsOptions,
    getSexualOrientations,
    getRaces,
    getEthnicities,
    getBuildInfo,
} = generalInfoService;
const {getDevicesModels, getDevicesStatuses, getDeviceFields} = deviceService;
const {
    getNonKitDevicesSpecifications,
    getKitSpecifications,
    getKitHomeLocations,
    getKitLocations,
    getKitAllLocations,
    getKitStatuses,
    getKitAssignmentStatuses,
} = kitService;
const {getOrderNames, getOrderFrequencies, getOrderTypes} = ordersService;
const {getTaskCategories} = taskTemplatesService;
const {getContactTypes, getServiceProviders} = vendorDictionaryService;
const {getTypesList, getMeasurementUnits} = observationService;
const {getOrderIgnoreReasons} = ordersService;
const {getAppointmentCancelReasons, getArrivalWindowTimeSlots} = appointmentCancelReasonsService;
const {getServiceRequestCancelReasons} = serviceRequestCancelReasonsService;
const {getVendorSubstatuses} = vendorsService;
const {getUiManagedSettings} = missionControlConfigurationService;
const {getAppointmentVisitTypes} = spiService;
const {getVoidedReasons} = patientService;

type CommonDataMapType = {
    [T in keyof CommonData]: () => Promise<CommonData[T]>;
};

const COMMON_DATA_MAP: CommonDataMapType = {
    [commonDataNames.PAYORS]: getPayors,
    [commonDataNames.SPONSORS]: getSponsors,
    [commonDataNames.PODS]: getPods,
    [commonDataNames.PRODUCTS]: getProducts,
    [commonDataNames.TIME_ZONES]: getTimeZones,
    [commonDataNames.STATES]: getStates,
    [commonDataNames.MISSION_CONTROLS]: getMissionControls,
    [commonDataNames.MEASUREMENT_UNITS]: getMeasurementUnits,
    [commonDataNames.DEVICES_MODELS]: getDevicesModels,
    [commonDataNames.DEVICES_STATUSES]: getDevicesStatuses,
    [commonDataNames.DEVICE_FIELDS]: getDeviceFields,
    [commonDataNames.NON_KIT_DEVICES_SPECIFICATIONS]: getNonKitDevicesSpecifications,
    [commonDataNames.KIT_SPECIFICATIONS]: getKitSpecifications,
    [commonDataNames.KIT_HOME_LOCATIONS]: getKitHomeLocations,
    [commonDataNames.KIT_LOCATIONS]: getKitLocations,
    [commonDataNames.KIT_STATUSES]: getKitStatuses,
    [commonDataNames.KIT_ASSIGNMENT_STATUSES]: getKitAssignmentStatuses,
    [commonDataNames.KIT_LOCATIONS_ALL]: getKitAllLocations,
    [commonDataNames.INCIDENTS_STATUSES]: getIncidentsStatuses,
    [commonDataNames.THRESHOLD_TYPES]: getThresholdTypes,
    [commonDataNames.ISO_LANGUAGES]: getIsoLanguages,
    [commonDataNames.GENDERS]: getGenders,
    [commonDataNames.GENDER_IDENTITIES]: getGenderIdentities,
    [commonDataNames.ORDER_NAMES]: getOrderNames,
    [commonDataNames.ORDER_FREQUENCIES]: getOrderFrequencies,
    [commonDataNames.ORDER_TYPES]: getOrderTypes,
    [commonDataNames.TASKS_TEMPLATES_CATEGORIES]: getTaskCategories,
    [commonDataNames.CONTACT_TYPES]: getContactTypes,
    [commonDataNames.RECENT_METRIC_TYPES]: getTypesList,
    [commonDataNames.ORDER_IGNORE_REASONS]: getOrderIgnoreReasons,
    [commonDataNames.APPOINTMENT_CANCEL_REASONS]: getAppointmentCancelReasons,
    [commonDataNames.SERVICE_REQUEST_CANCEL_REASONS]: getServiceRequestCancelReasons,
    [commonDataNames.CLUSTERS]: getClusters,
    [commonDataNames.NURSE_PROFILES]: getNurseProfiles,
    [commonDataNames.NURSE_ROLES]: getNurseRoles,
    [commonDataNames.LOCAL_911_TYPES]: getLocal911Types,
    [commonDataNames.ADDITIONAL_LOCAL_EMERGENCY_TYPES]: getAdditionalLocalEmergencyTypes,
    [commonDataNames.HAND_OFF_STATUSES]: getHandOffStatuses,
    [commonDataNames.HAND_OFF_URGENCIES]: getHandOffUrgencies,
    [commonDataNames.HAND_OFF_CANCELLATION_STATUSES]: getHandOffCancellationStatuses,
    [commonDataNames.HAND_OFF_CANCELLATION_URGENCIES]: getHandOffCancellationUrgencies,
    [commonDataNames.EHRS]: getEhrs,
    [commonDataNames.RELATIONS]: getRelations,
    [commonDataNames.MESSAGE_TYPES]: getMessageTypes,
    [commonDataNames.PHASE_OF_CARE]: getPhaseOfCare,
    [commonDataNames.NURSE_OPTIONS]: getNurseOptions,
    [commonDataNames.VENDOR_SUBSTATUSES]: getVendorSubstatuses,
    [commonDataNames.GENERAL_APPOINTMENTS_TYPES]: getGeneralAppointmentTypes,
    [commonDataNames.UI_MANAGED_SETTINGS]: getUiManagedSettings,
    [commonDataNames.SELF_CHECK_IN_OPTIONS]: getSelfCheckInOptions,
    [commonDataNames.SELF_CHECK_OUT_OPTIONS]: getSelfCheckOutOptions,
    [commonDataNames.ACTIVITY_REFUSE_REASONS]: getActivityRefuseReasonsOptions,
    [commonDataNames.ARRIVAL_WINDOW_TIME_SLOTS]: getArrivalWindowTimeSlots,
    [commonDataNames.APPOINTMENT_VISIT_TYPES]: getAppointmentVisitTypes,
    [commonDataNames.IMPROVEMENT_LOG_EVENT_TYPES]: getImprovementLogEventTypes,
    [commonDataNames.IMPROVEMENT_LOG_ROOT_CAUSES]: getCausalFactors,
    [commonDataNames.IMPROVEMENT_LOG_RESPONSIBLE_ACTORS]: getResponsibleActors,
    [commonDataNames.IMPROVEMENT_LOG_CLOSED_REPORTERS]: getClosedPerformanceImprovementLogReporters,
    [commonDataNames.IMPROVEMENT_LOG_IN_REVIEW_REPORTERS]: getInReviewPerformanceImprovementLogReporters,
    [commonDataNames.IMPROVEMENT_LOG_NEW_REPORTERS]: getNewPerformanceImprovementLogReporters,
    [commonDataNames.IMPROVEMENT_LOG_LEVELS_OF_INVESTIGATION]: getLevelsOfInvestigation,
    [commonDataNames.SERVICE_PROVIDERS]: getServiceProviders,
    [commonDataNames.SEXUAL_ORIENTATIONS]: getSexualOrientations,
    [commonDataNames.RACES]: getRaces,
    [commonDataNames.ETHNICITIES]: getEthnicities,
    [commonDataNames.SUBMISSION_TYPES]: getSubmissionTypes,
    [commonDataNames.VOIDED_REASONS]: getVoidedReasons,
    [commonDataNames.BUILD_INFO]: getBuildInfo,
};

export default COMMON_DATA_MAP;
