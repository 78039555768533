import {call, put} from 'redux-saga/effects';

import getCommonDataService from '@/services/commonDataService';

import {getCommonData, getCommonDataFail, getCommonDataSuccess} from './slice';

export default function* getCommonDataWorker(keys) {
    const toLoad = getCommonDataService.checkLoad(keys);

    if (toLoad.length) {
        yield put(getCommonData(toLoad));
        try {
            const data = yield call(getCommonDataService.getCommonData, toLoad);
            yield put(getCommonDataSuccess({data, keys: toLoad}));
        } catch (error) {
            yield put(getCommonDataFail(toLoad));
            throw error;
        }
    }
}
