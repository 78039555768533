import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';

import styles from './toastMessage.module.scss';

type ToastMessageProps = {
    type: 'info' | 'success' | 'warning' | 'error' | 'default';
    title: string;
    message: string;
};

export const ToastMessage = ({type, title, message}: ToastMessageProps) => {
    const isSuccess = ['success', 'info', 'default'].includes(type);
    const isError = type === 'error';
    const isWarning = type === 'warning';
    return (
        <div
            className={classnames(styles.wrapper, {
                [styles.successWrapper]: isSuccess,
                [styles.errorWrapper]: isError,
                [styles.warningWrapper]: isWarning,
            })}
        >
            {isSuccess && <CheckCircleIcon classes={{root: styles.successIcon}} />}
            {isError && <CancelIcon classes={{root: styles.errorIcon}} />}
            {isWarning && <WarningIcon classes={{root: styles.warningIcon}} />}
            <div className={styles.content}>
                {/*TODO update Typography to MHTypography. Already tried here to update and received strange behaviour with failed tests at src/utils/__tests__/alertUtils.test.ts */}
                <Typography classes={{root: styles.title}}>{title}</Typography>
                <Typography classes={{root: styles.message}}>{message}</Typography>
            </div>
        </div>
    );
};
