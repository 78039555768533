const PAYORS = 'payors';
const SPONSORS = 'sponsors';
const PODS = 'pods';
const PRODUCTS = 'products';
const TIME_ZONES = 'timeZones';
const STATES = 'states';
const MISSION_CONTROLS = 'missionControls';
const NURSE_OPTIONS = 'nurseOptions';
const MEASUREMENT_UNITS = 'measurementUnits';
const DEVICES_MODELS = 'deviceModels';
const DEVICES_STATUSES = 'devicesStatuses';
const DEVICE_FIELDS = 'deviceFields';
const NON_KIT_DEVICES_SPECIFICATIONS = 'nonKitDevicesSpecifications';
const KIT_SPECIFICATIONS = 'kitSpecifications';
const KIT_HOME_LOCATIONS = 'kitHomeLocations';
const KIT_LOCATIONS = 'kitLocations';
const KIT_STATUSES = 'kitStatuses';
const THRESHOLD_TYPES = 'thresholdTypes';
const INCIDENTS_STATUSES = 'incidentsStatuses';
const ISO_LANGUAGES = 'isoLanguages';
const GENDERS = 'genders';
const GENDER_IDENTITIES = 'genderIdentities';
const ORDER_NAMES = 'orderNames';
const ORDER_FREQUENCIES = 'orderFrequencies';
const ORDER_TYPES = 'orderTypes';
const TASKS_TEMPLATES_CATEGORIES = 'taskCategories';
const CONTACT_TYPES = 'contactTypes';
const KIT_ASSIGNMENT_STATUSES = 'kitAssignmentStatuses';
const KIT_LOCATIONS_ALL = 'kitLocationsAll';
const VITAL_SIGNS_TYPES = 'vitalSignsTypes';
const ORDER_IGNORE_REASONS = 'orderIgnoreReasons';
const APPOINTMENT_CANCEL_REASONS = 'appointmentCancelReasons';
const SERVICE_REQUEST_CANCEL_REASONS = 'serviceRequestCancelReasons';
const CLUSTERS = 'clusters';
const NURSE_PROFILES = 'nurseProfiles';
const NURSE_ROLES = 'nurseRoles';
const LOCAL_911_TYPES = 'local911Types';
const ADDITIONAL_LOCAL_EMERGENCY_TYPES = 'additionalLocalEmergencyTypes';
const HAND_OFF_STATUSES = 'handOffStatuses';
const HAND_OFF_URGENCIES = 'handOffUrgencies';
const HAND_OFF_CANCELLATION_STATUSES = 'handOffCancellationStatuses';
const HAND_OFF_CANCELLATION_URGENCIES = 'handOffCancellationUrgencies';
const EHRS = 'ehrs';
const RELATIONS = 'relations';
const MESSAGE_TYPES = 'messageTypes';
const PHASE_OF_CARE = 'phaseOfCare';
const VENDOR_SUBSTATUSES = 'vendorSubstatuses';
const GENERAL_APPOINTMENTS_TYPES = 'generalAppointmentsTypes';
const UI_MANAGED_SETTINGS = 'uiManagedSettings';
const SELF_CHECK_IN_OPTIONS = 'selfCheckInOptions';
const SELF_CHECK_OUT_OPTIONS = 'selfCheckOutOptions';
const ACTIVITY_REFUSE_REASONS = 'activityRefuseReasons';
const ARRIVAL_WINDOW_TIME_SLOTS = 'arrivalWindowTimeSlots';
const IMPROVEMENT_LOG_EVENT_TYPES = 'improvementLogEventTypes';
const IMPROVEMENT_LOG_ROOT_CAUSES = 'improvementLogRootCauses';
const IMPROVEMENT_LOG_CLOSED_REPORTERS = 'improvementLogClosedReporters';
const IMPROVEMENT_LOG_IN_REVIEW_REPORTERS = 'improvementLogInReviewReporters';
const IMPROVEMENT_LOG_NEW_REPORTERS = 'improvementLogNewReporters';
const IMPROVEMENT_LOG_RESPONSIBLE_ACTORS = 'improvementLogResponsibleActors';
const IMPROVEMENT_LOG_LEVELS_OF_INVESTIGATION = 'improvementLogLevelsOfInvestigation';
const SERVICE_PROVIDERS = 'serviceProviders';
const APPOINTMENT_VISIT_TYPES = 'appointmentVisitTypes';
const SEXUAL_ORIENTATIONS = 'sexualOrientations';
const RACES = 'races';
const ETHNICITIES = 'ethnicities';
const RECENT_METRIC_TYPES = 'recentMetricTypes';
const SUBMISSION_TYPES = 'submissionTypes';
const VOIDED_REASONS = 'voidedReasons';
const BUILD_INFO = 'buildInfo';

export {
    ACTIVITY_REFUSE_REASONS,
    ADDITIONAL_LOCAL_EMERGENCY_TYPES,
    APPOINTMENT_CANCEL_REASONS,
    APPOINTMENT_VISIT_TYPES,
    ARRIVAL_WINDOW_TIME_SLOTS,
    BUILD_INFO,
    CLUSTERS,
    CONTACT_TYPES,
    DEVICE_FIELDS,
    DEVICES_MODELS,
    DEVICES_STATUSES,
    EHRS,
    ETHNICITIES,
    GENDER_IDENTITIES,
    GENDERS,
    GENERAL_APPOINTMENTS_TYPES,
    HAND_OFF_CANCELLATION_STATUSES,
    HAND_OFF_CANCELLATION_URGENCIES,
    HAND_OFF_STATUSES,
    HAND_OFF_URGENCIES,
    IMPROVEMENT_LOG_CLOSED_REPORTERS,
    IMPROVEMENT_LOG_EVENT_TYPES,
    IMPROVEMENT_LOG_IN_REVIEW_REPORTERS,
    IMPROVEMENT_LOG_LEVELS_OF_INVESTIGATION,
    IMPROVEMENT_LOG_NEW_REPORTERS,
    IMPROVEMENT_LOG_RESPONSIBLE_ACTORS,
    IMPROVEMENT_LOG_ROOT_CAUSES,
    INCIDENTS_STATUSES,
    ISO_LANGUAGES,
    KIT_ASSIGNMENT_STATUSES,
    KIT_HOME_LOCATIONS,
    KIT_LOCATIONS,
    KIT_LOCATIONS_ALL,
    KIT_SPECIFICATIONS,
    KIT_STATUSES,
    LOCAL_911_TYPES,
    MEASUREMENT_UNITS,
    MESSAGE_TYPES,
    MISSION_CONTROLS,
    NON_KIT_DEVICES_SPECIFICATIONS,
    NURSE_OPTIONS,
    NURSE_PROFILES,
    NURSE_ROLES,
    ORDER_FREQUENCIES,
    ORDER_IGNORE_REASONS,
    ORDER_NAMES,
    ORDER_TYPES,
    PAYORS,
    PHASE_OF_CARE,
    PODS,
    PRODUCTS,
    RACES,
    RECENT_METRIC_TYPES,
    RELATIONS,
    SELF_CHECK_IN_OPTIONS,
    SELF_CHECK_OUT_OPTIONS,
    SERVICE_PROVIDERS,
    SERVICE_REQUEST_CANCEL_REASONS,
    SEXUAL_ORIENTATIONS,
    SPONSORS,
    STATES,
    SUBMISSION_TYPES,
    TASKS_TEMPLATES_CATEGORIES,
    THRESHOLD_TYPES,
    TIME_ZONES,
    UI_MANAGED_SETTINGS,
    VENDOR_SUBSTATUSES,
    VITAL_SIGNS_TYPES,
    VOIDED_REASONS,
};
