import {
    CreatedResponseDTO,
    CreateTaskTemplateDTO,
    SimpleMessageResponseDTO,
    TaskTemplateDTO,
} from '@/types/gatewayDataModels';

import urls from '../constants/endpoints/gateway';
import httpApi from './httpApi_new';

class TaskTemplatesService {
    getTasks = ({
        searchBy,
        sponsor,
        product,
        payor,
        category,
        sortingColumn,
        sortingOrder,
    }: {
        searchBy: string;
        sponsor: string;
        product: string;
        payor: string;
        category: string;
        sortingColumn: string;
        sortingOrder: string;
    }): Promise<TaskTemplateDTO[]> => {
        const queryParams: {
            name?: string;
            sponsor?: string;
            product?: string;
            payor?: string;
            category?: string;
            sort: string;
        } = {
            sort: `${sortingColumn}${sortingOrder ? `,${sortingOrder}` : ''}`,
            name: encodeURIComponent(searchBy),
            sponsor,
            product,
            payor,
            category: encodeURIComponent(category),
        };
        return httpApi.get({
            url: urls.TASKS_TEMPLATES,
            queryParams,
        });
    };

    getTasksTemplate = (taskTemplateId: number): Promise<TaskTemplateDTO> =>
        httpApi.get({url: urls.TASKS_TEMPLATES_BY_ID(taskTemplateId)});

    createTasksTemplate = (data: CreateTaskTemplateDTO): Promise<CreatedResponseDTO> =>
        httpApi.post({
            url: urls.TASKS_TEMPLATES,
            data,
        });

    updateTasksTemplate = (data: CreateTaskTemplateDTO & {id: number}): Promise<TaskTemplateDTO> =>
        httpApi.put({
            url: urls.TASKS_TEMPLATES_BY_ID(data.id),
            data,
        });

    getTaskCategories = (): Promise<
        (
            | 'Admission'
            | 'Med'
            | 'Infusion'
            | 'Lab'
            | 'DME & O2'
            | 'Transport'
            | 'Vascular Access'
            | 'Courier'
            | 'Discharge'
            | 'Other'
        )[]
    > => httpApi.get({url: urls.TASKS_TEMPLATES_CATEGORIES});

    updateStatus = (id: number, value: boolean): Promise<SimpleMessageResponseDTO> => {
        const queryParams = {disabled: value};
        return httpApi.patch({
            url: urls.TASKS_TEMPLATES_VISIBILITY(id),
            queryParams,
        });
    };
}

const taskTemplatesService = new TaskTemplatesService();
export default taskTemplatesService;
