import {MHTableSortOrder} from '@/components/base';
import urls from '@/constants/endpoints/gateway';
import {
    AcknowledgeOrderCancelResponseDTO,
    ActivityRequiredHistory,
    ActivityRequiredRequestDTO,
    ActivityRescheduleRequestDTO,
    ActivityResponseDTO,
    ActivitySpecificationDTO,
    ActivityStatusHistoryResponseDTO,
    ActivityWithFlagsResponseDTO,
    AddFlexibleOrderActivitiesRequestDTO,
    CategoryDTO,
    CreatedResponseDTO,
    CreateMultiDayMultipleActivitiesInHomeAppointmentRequestDTO,
    CreateOrderRequestDTO,
    CreateOrUpdateInHomeAppointmentsRequestDTO,
    GetActiveInHomeOrdersQueryDTO,
    MultipleDaysRelatedOrderBundleDTO,
    MultiRelatedOrderDTO,
    NewOrdersCountDTO,
    OrderAttributesDTO,
    OrderCancellationDetail,
    OrderChangeDTO,
    OrderFrequencyDTO,
    OrderHistoryDTO,
    OrderIgnoreRequestDTO,
    OrderSpecificationDTO,
    OrderSpecificationModelDTO,
    OrderStates,
    OrderToAcknowledgeResponseDTO,
    OrderTypeResponseDTO,
    OrderWithActivitiesResponseDTO,
    PageOrderResponseDTO,
    PatientActiveInHomeOrdersInfoModel,
    PlanOrderPRNActivitiesRequestDTO,
    PreDocumentAppointmentsCountDTO,
    ReasonOptionDTO,
    RelatedOrderDTO,
    SimpleMessageResponseDTO,
    TBDActivityScheduleRequestDTO,
    UpdateActivityLabelsRequestDTO,
} from '@/types/gatewayDataModels';

import httpApi from './httpApi_new';

type DisableErrorCallback = (error?: unknown) => boolean;

class OrdersService {
    getOrderNames = () => httpApi.get<OrderSpecificationDTO[]>({url: urls.ORDER_ORDER_NAMES});
    getOrderSpecificationModel = (patientId: number) =>
        httpApi.get<OrderSpecificationModelDTO>({url: urls.ORDER_SPECIFICATION_MODEL(patientId)});
    getOrderFrequencies = () => httpApi.get<OrderFrequencyDTO[]>({url: urls.ORDER_FREQUENCIES});
    getOrderTypes = () => httpApi.get<OrderTypeResponseDTO[]>({url: urls.ORDER_ORDER_TYPES});
    getOrderCategories = (patientId: number) =>
        httpApi.get<CategoryDTO[]>({url: urls.ORDER_CATEGORIES(patientId.toString())});

    getRelatedOrders = ({orderId, scheduledDate}: {orderId: number; scheduledDate: string}) =>
        httpApi.get<RelatedOrderDTO[]>({
            url: urls.ORDER_RELATED(orderId),
            queryParams: {
                scheduled_date: scheduledDate,
            },
        });

    getOrderIgnoreReasons = () => httpApi.get<ReasonOptionDTO[]>({url: urls.ORDER_IGNORE_REASONS});

    restoreIgnoredOrder = (id: number) => httpApi.put<SimpleMessageResponseDTO>({url: urls.ORDER_RESTORE_IGNORED(id)});

    ignoreOrder = (id: number, data: OrderIgnoreRequestDTO) =>
        httpApi.put<SimpleMessageResponseDTO>({url: urls.ORDER_IGNORE(id), data});

    getRelatedMultipleOrders = ({orderId, activityId}: {orderId: number; activityId: number}) =>
        httpApi.get<MultiRelatedOrderDTO>({
            url: urls.ORDER_RELATED_MULTIPLE(orderId),
            queryParams: {
                activityId,
            },
        });

    getOrdersListWithoutActivities = ({
        page,
        size,
        sortingColumn,
        sortingOrder,
        patient_ids,
        states,
        general,
        acknowledged,
        isNew,
        interfaceOrders,
    }: {
        page?: number;
        size?: number;
        sortingColumn?: string;
        sortingOrder?: MHTableSortOrder;
        patient_ids?: number[];
        states?: OrderStates;
        general?: boolean;
        acknowledged?: boolean;
        isNew?: boolean;
        interfaceOrders?: 'EHR' | 'manual';
    }) => {
        const queryParams = {
            page,
            size,
            ...(sortingColumn && {sort: `${sortingColumn}${sortingOrder ? `,${sortingOrder}` : ''}`}),
            ...(interfaceOrders && {interface_orders: interfaceOrders === 'EHR'}),
            ...(isNew && {new: isNew}),
            ...((general || general === false) && {general}),
            ...(acknowledged && {acknowledged}),
            ...(patient_ids && {patient_ids}),
            ...(states && {states}),
        };
        return httpApi.get<PageOrderResponseDTO>({
            url: urls.ORDERS_WITHOUT_ACTIVITIES,
            queryParams,
        });
    };

    getActiveInHomeOrders = (queryParams: GetActiveInHomeOrdersQueryDTO) => {
        return httpApi.get<PatientActiveInHomeOrdersInfoModel>({
            url: urls.ACTIVE_IN_HOME_ORDERS,
            queryParams,
        });
    };

    createManualOrder = (data: CreateOrderRequestDTO) => httpApi.post<CreatedResponseDTO>({url: urls.ORDERS, data});

    cancelManualOrder = (
        id: number,
        cancelEffectiveDateTime: string,
        cancelActivitiesInEffectiveDay: boolean,
    ): Promise<SimpleMessageResponseDTO> =>
        httpApi.patch({
            url: urls.ORDER_CANCEL(id),
            data: {id},
            ...(cancelEffectiveDateTime
                ? {queryParams: {cancelEffectiveDateTime, cancelActivitiesInEffectiveDay}}
                : null),
        });

    cancelOrder = (data: OrderCancellationDetail): Promise<AcknowledgeOrderCancelResponseDTO> =>
        httpApi.patch({
            url: urls.ORDER_CANCEL_ACKNOWLEDGE(data.id),
            data,
        });

    doAcknowledge = ({id, data}: {id: number; data: CreateOrderRequestDTO}): Promise<OrderWithActivitiesResponseDTO> =>
        httpApi.post({url: urls.ORDER_ACKNOWLEDGE(id), data});

    getSubcategories = ({category}: {category: string}): Promise<string[]> =>
        httpApi.get({
            url: urls.ORDER_SUBCATEGORIES,
            queryParams: {
                category,
            },
        });

    getOrderAttributes = ({
        orderId,
        categoryName,
        subcategory,
        attributeValue,
        response,
    }: {
        orderId: number;
        categoryName: string;
        subcategory?: string;
        attributeValue?: string;
        response?: string;
    }): Promise<OrderAttributesDTO[]> =>
        httpApi.get({
            url: urls.ORDER_ATTRIBUTES,
            queryParams: {
                orderId,
                categoryName,
                ...(subcategory ? {subcategory} : null),
                ...(attributeValue ? {attributeValue} : null),
                ...(response ? {response} : null),
            },
        });

    getOrderAttributesById = (orderId: number): Promise<ActivityWithFlagsResponseDTO[]> =>
        httpApi.get({url: urls.ORDER_ATTRIBUTES_BY_ID(orderId)});

    getOrderChangedById = (orderId: number): Promise<OrderChangeDTO> =>
        httpApi.get({url: urls.ORDER_CHANGES_BY_ID(orderId)});

    getOrder = ({orderId}: {orderId: number}): Promise<OrderWithActivitiesResponseDTO> =>
        httpApi.get({url: urls.ORDER(orderId)});

    getOrderActivityComments = (activityId: number) =>
        httpApi.get<ActivityRequiredHistory[]>({url: urls.REQUIRED_ACTIVITY(activityId)});

    getActivityHistory = (activityId: number): Promise<ActivityStatusHistoryResponseDTO[]> =>
        httpApi.get({url: urls.ACTIVITY_HISTORY(activityId)});

    updateOrderActivity = (
        {
            activityId,
            data,
        }: {
            activityId: number;
            data: ActivityRequiredRequestDTO;
        },
        opts?: {disableGeneralNotify: DisableErrorCallback},
    ): Promise<ActivityResponseDTO> => httpApi.post({url: urls.REQUIRED_ACTIVITY(activityId), data, ...opts});

    getOrderActivitySpecs = ({
        orderId,
        all = true,
        isRegular = true,
        isPrn = false,
    }: {
        orderId: number;
        all?: boolean;
        isRegular?: boolean;
        isPrn?: boolean;
    }): Promise<ActivitySpecificationDTO[]> =>
        httpApi.get({
            url: urls.ORDER_ACTIVITY_SPECIFICATIONS(orderId),
            queryParams: all ? null : {isRegular: isRegular, isPrn: isPrn},
        });

    updateFlexibleOrderActivities = ({
        orderId,
        data,
    }: {
        orderId: number;
        data: AddFlexibleOrderActivitiesRequestDTO;
    }): Promise<SimpleMessageResponseDTO> => httpApi.put({url: urls.ORDER_FLEXIBLE_ORDER_ACTIVITIES(orderId), data});

    addPRNActivities = ({
        orderId,
        data,
    }: {
        orderId: number;
        data: PlanOrderPRNActivitiesRequestDTO;
    }): Promise<SimpleMessageResponseDTO> => httpApi.put({url: urls.ORDER_ADD_PRN_ACTIVITIES(orderId), data});

    updateActivityDate = (
        {
            activityId,
            data,
        }: {
            activityId: number;
            data: ActivityRescheduleRequestDTO;
        },
        opts?: {disableGeneralNotify: DisableErrorCallback},
    ): Promise<ActivityResponseDTO> => httpApi.post({url: urls.UPDATE_ACTIVITY_DATE(activityId), data, ...opts});

    setActivityDate = (
        {
            activityId,
            data,
        }: {
            activityId: number;
            data: TBDActivityScheduleRequestDTO;
        },
        opts?: {disableGeneralNotify: DisableErrorCallback},
    ): Promise<ActivityResponseDTO> => httpApi.post({url: urls.SET_ACTIVITY_DATE(activityId), data, ...opts});

    getMultipleDaysOrdersForSelection = ({
        orderId,
        scheduleDate,
    }: {
        orderId: number;
        scheduleDate: string;
    }): Promise<MultipleDaysRelatedOrderBundleDTO> =>
        httpApi.get({
            url: urls.MULTIPLE_DAYS_MULTIPLE_ACTIVITIES_ORDERS(orderId.toString()),
            queryParams: {
                scheduleDate,
            },
        });

    createMultiDayMultiActivitiesAppointments = (
        data: CreateMultiDayMultipleActivitiesInHomeAppointmentRequestDTO,
    ): Promise<CreatedResponseDTO[]> =>
        httpApi.post({
            url: urls.CREATE_MULTI_DAYS_MULTI_ACTIVITIES_APPOINTMENTS,
            data,
        });

    updateMultiDayMultiActivitiesAppointments = (
        data: CreateOrUpdateInHomeAppointmentsRequestDTO,
    ): Promise<SimpleMessageResponseDTO> =>
        httpApi.put({
            url: urls.CREATE_MULTI_DAYS_MULTI_ACTIVITIES_APPOINTMENTS,
            data,
        });

    saveAcknowledgeChanges = (data: {id: number; remove_last_day: boolean}): Promise<SimpleMessageResponseDTO> =>
        httpApi.patch({url: urls.ACKNOWLEDGE_CHANGES(data.id), queryParams: {remove_last_day: data.remove_last_day}});

    getOrderToAcknowledgeList = (patientId: number): Promise<OrderToAcknowledgeResponseDTO[]> =>
        httpApi.get({url: urls.ACKNOWLEDGE_LIST, queryParams: {patient_id: patientId}});

    updateOrderActivityLabels = ({orderId, data}: {orderId: number; data: UpdateActivityLabelsRequestDTO}) =>
        httpApi.patch<ActivityWithFlagsResponseDTO[]>({url: urls.ORDER_ACTIVITY_LABELS(orderId), data});

    updateOrderActivityLabel = ({
        activityId,
        label,
    }: {
        activityId: number;
        label: string;
    }): Promise<SimpleMessageResponseDTO> =>
        httpApi.patch({url: urls.ACTIVITY_LABEL(activityId), queryParams: {label: encodeURIComponent(label)}});

    getNewOrdersCounter = (patientId: number): Promise<NewOrdersCountDTO> =>
        httpApi.get({
            url: urls.NEW_ORDERS_COUNTER,
            queryParams: {patientId},
        });

    getPreDocumentationAppointmentsCounter = (patientId: number): Promise<PreDocumentAppointmentsCountDTO> =>
        httpApi.get({
            url: urls.PRE_DOCUMENTATION_APPOINTMENTS_COUNTER,
            queryParams: {patientId},
        });

    getOrderHistory = (orderId: number): Promise<OrderHistoryDTO[]> => httpApi.get({url: urls.ORDER_HISTORY(orderId)});
}

const ordersService = new OrdersService();
export default ordersService;
