import urls from '@/constants/endpoints/gateway';
import {
    ActivityDTO,
    ActivitySpecificationDTO,
    ActorDTO,
    ActorTypeResponseDTO,
    CreatedResponseCodeDTO,
    CreatedResponseDTO,
    SiteDTO,
    Town,
    VendorDTO,
    VendorTreeDTO,
} from '@/types/gatewayDataModels';
import {ServiceProviderDTO} from '@/types/performanceIssueTrackerDataModels';

import httpApi from './httpApi_new';

type DisableErrorCallback = (error?: unknown) => boolean;

class VendorDictionaryService {
    getActorTypes = (): Promise<ActorTypeResponseDTO[]> =>
        httpApi.get({
            url: urls.ACTOR_TYPES,
        });

    getCities = (): Promise<Town[]> =>
        httpApi.get({
            url: urls.GEOGRAPHY_CITIES,
        });

    getTree = (): Promise<VendorTreeDTO[]> =>
        httpApi.get({
            url: urls.VENDOR_DICTIONARY_TREE,
        });

    getVendor = (vendorCode: string): Promise<VendorDTO> =>
        httpApi.get({
            url: urls.VENDOR(vendorCode),
        });

    updateVendor = (
        {vendorCode, data}: {vendorCode: string; data: VendorDTO},
        opts?: {disableGeneralNotify: DisableErrorCallback},
    ): Promise<CreatedResponseDTO> =>
        httpApi.put({
            url: urls.VENDOR(vendorCode),
            data,
            ...opts,
        });

    createVendor = (
        data: VendorDTO,
        opts?: {disableGeneralNotify: DisableErrorCallback},
    ): Promise<CreatedResponseCodeDTO> =>
        httpApi.post({
            url: urls.VENDORS,
            data,
            ...opts,
        });

    getSite = ({vendorCode, siteCode}: {vendorCode: string; siteCode: string}): Promise<SiteDTO> =>
        httpApi.get({
            url: urls.SITE(vendorCode, siteCode),
        });

    updateSite = ({
        vendorCode,
        siteCode,
        data,
    }: {
        vendorCode: string;
        siteCode: string;
        data: SiteDTO;
    }): Promise<unknown> =>
        httpApi.put({
            url: urls.SITE(vendorCode, siteCode),
            data,
        });

    createSite = ({vendorCode, data}: {vendorCode: string; data: SiteDTO}): Promise<CreatedResponseCodeDTO> =>
        httpApi.post({
            url: urls.SITES(vendorCode),
            data,
        });

    getActor = ({
        vendorCode,
        siteCode,
        actorCode,
    }: {
        vendorCode: string;
        siteCode: string;
        actorCode: string;
    }): Promise<ActorDTO> =>
        httpApi.get({
            url: urls.ACTOR(vendorCode, siteCode, actorCode),
        });

    removeActor = ({
        vendorCode,
        siteCode,
        actorCode,
    }: {
        vendorCode: string;
        siteCode: string;
        actorCode: string;
    }): Promise<unknown> =>
        httpApi.delete({
            url: urls.ACTOR(vendorCode, siteCode, actorCode),
        });

    getActorActivities = ({actorCode}: {actorCode: string}): Promise<ActivitySpecificationDTO[]> =>
        httpApi.get({
            url: urls.ACTOR_ACTIVITIES_GET(actorCode),
        });

    updateActor = ({
        vendorCode,
        siteCode,
        actorCode,
        data,
    }: {
        vendorCode: string;
        siteCode: string;
        actorCode: string;
        data: ActorDTO;
    }): Promise<unknown> =>
        httpApi.put({
            url: urls.ACTOR(vendorCode, siteCode, actorCode),
            data,
        });

    createActor = ({
        vendorCode,
        siteCode,
        data,
    }: {
        vendorCode: string;
        siteCode: string;
        data: ActorDTO;
    }): Promise<CreatedResponseCodeDTO> =>
        httpApi.post({
            url: urls.ACTORS(vendorCode, siteCode),
            data,
        });

    getActivity = ({
        vendorCode,
        siteCode,
        actorCode,
        activityCode,
    }: {
        vendorCode: string;
        siteCode: string;
        actorCode: string;
        activityCode: string;
    }): Promise<ActivityDTO> =>
        httpApi.get({
            url: urls.ACTIVITY(vendorCode, siteCode, actorCode, activityCode),
        });

    updateActivity = ({
        vendorCode,
        siteCode,
        actorCode,
        activityCode,
        data,
    }: {
        vendorCode: string;
        siteCode: string;
        actorCode: string;
        activityCode: string;
        data: ActivityDTO;
    }): Promise<unknown> =>
        httpApi.put({
            url: urls.ACTIVITY(vendorCode, siteCode, actorCode, activityCode),
            data,
        });

    createActivity = ({
        vendorCode,
        siteCode,
        actorCode,
        data,
    }: {
        vendorCode: string;
        siteCode: string;
        actorCode: string;
        data: ActivityDTO;
    }): Promise<CreatedResponseCodeDTO> =>
        httpApi.post({
            url: urls.ACTIVITY_CREATE(vendorCode, siteCode, actorCode),
            data,
        });

    getActivities = (): Promise<ActivitySpecificationDTO[]> =>
        httpApi.get({
            url: urls.ACTIVITIES,
        });

    getContactTypes = (): Promise<string[]> => httpApi.get({url: urls.CONTACT_TYPES_GET});

    exportActorActivity = () =>
        httpApi.get({
            url: urls.EXPORT_ACTOR_ACTIVITY,
            customConfig: {headers: {'Content-Type': 'text/csv;charset=ISO-8859-1'}},
            customHandler: async (response) => {
                return await response.blob();
            },
        });

    isEverUsed = (data: {vendorCode: string; siteCode: string; actorCode: string}): Promise<boolean> =>
        httpApi.get({
            url: urls.ACTOR_IS_EVER_USED(data),
        });

    checkIfActorHasActiveAppointment = (assignmentGroupId: string): Promise<boolean> =>
        httpApi.get({
            url: urls.ACTOR_HAS_ACTIVE_APPOINTMENT(assignmentGroupId),
        });

    getServiceProviders = () => httpApi.get<ServiceProviderDTO[]>({url: urls.GET_AVAILABLE_VENDOR_SITE_OPTIONS});
}

const vendorDictionaryService = new VendorDictionaryService();
export default vendorDictionaryService;
