import {STATUSES} from '@/constants/constants';
import urls from '@/constants/endpoints/gateway';
import httpApi from '@/services/httpApi_new';
import {
    AneltoBatteryStatusResponseDTO,
    CreatedResponseDTO,
    CreateOrUpdateDeviceRequestDTO,
    DeviceModel,
    DeviceResponseDTO,
    DeviceStatus,
    DeviceTypeSpecificFieldSpecification,
    EquipmentKitWithDependenciesResponseDTO,
    IdsRequestParamDTO,
    PageDeviceResponseDTO,
    RegistrationTokenDTO,
    SimpleMessageResponseDTO,
    WifiPhoneValidationResponse,
} from '@/types/gatewayDataModels';

const {READY_FOR_USE, INCOMPLETE} = STATUSES;

class DeviceService {
    getDevices = (params: {
        home_location_id?: number;
        kit_assignment_statuses?: 'ASSIGNED' | 'NOT_ASSIGNED' | 'ANY';
        search_token?: string;
        sort?: string;
        statuses?: string;
        is_assigned?: boolean;
        page?: number;
        size?: number;
    }) =>
        httpApi.get<PageDeviceResponseDTO>({
            url: urls.DEVICES,
            queryParams: {
                ...params,
                page: params.page || 0,
                size: params.size || 50000,
            },
        });
    deleteDevice = (id: number) => httpApi.delete<SimpleMessageResponseDTO>({url: `${urls.DEVICES}/${id}`});
    getReadyEquipmentKits = () =>
        httpApi.get<EquipmentKitWithDependenciesResponseDTO[]>({
            url: urls.EQUIPMENT_KITS,
            queryParams: {
                statuses: [READY_FOR_USE, INCOMPLETE],
                is_assigned: false,
            },
        });
    assignDeviceToKit = (id: number, data: IdsRequestParamDTO) =>
        httpApi.post<SimpleMessageResponseDTO>({url: urls.ASSIGN_DEVICES_TO_KIT(id), data});
    getDevicesStatuses = () => httpApi.get<DeviceStatus[]>({url: urls.DEVICE_STATUSES});
    getDeviceFields = () =>
        httpApi.get<Record<string, DeviceTypeSpecificFieldSpecification>>({url: urls.DEVICE_FIELDS});

    getAneltoBatteryStatus = (serialNumber: string) =>
        httpApi.get<AneltoBatteryStatusResponseDTO[]>({
            url: urls.ANELTO_BATTERY_STATUS(serialNumber),
        });
    getDevicesModels = () => httpApi.get<DeviceModel[]>({url: urls.DEVICE_MODELS});
    getDevice = (id: number) => httpApi.get<DeviceResponseDTO>({url: urls.DEVICE_SINGLE(id)});
    generateQrCode = () => httpApi.get<RegistrationTokenDTO>({url: urls.DEVICES_NEW_QR});
    createDevice = (data: CreateOrUpdateDeviceRequestDTO) =>
        httpApi.post<CreatedResponseDTO>({url: urls.DEVICES, data});
    updateDevice = (id: number, data: CreateOrUpdateDeviceRequestDTO) =>
        httpApi.put<DeviceResponseDTO>({url: `${urls.DEVICES}/${id}`, data});
    validateYealinkDevice = ({sn, mac, validationMethod}: {sn: string; mac: string; validationMethod?: string}) => {
        const queryParams = {
            mac,
            validationMethod,
            sn: encodeURIComponent(sn),
        };
        return httpApi.get<WifiPhoneValidationResponse>({url: urls.VALIDATE_YEALINK_DEVICE, queryParams});
    };
}

const deviceService = new DeviceService();
export default deviceService;
