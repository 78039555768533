import {FeatureApiBean, ProductConfigurationResponseDTOv2, SimpleMessageResponseDTO} from '@/types/gatewayDataModels';

import urls from '../constants/endpoints/gateway';
import httpApi from './httpApi_new';

class MissionControlConfigurationService {
    getMissionControlConfiguration = (id: number): Promise<ProductConfigurationResponseDTOv2[]> =>
        httpApi.get({
            url: urls.MISSION_CONTROL_CONFIGURATION(id),
        });

    toggleMissionControlSetting = ({
        id,
        showNurseOption,
    }: {
        id: number;
        showNurseOption: string;
    }): Promise<SimpleMessageResponseDTO> =>
        httpApi.patch({
            url: urls.MISSION_CONTROL_CONFIGURATION(id),
            queryParams: {showNurseOption},
        });

    getUiManagedSettings = (): Promise<FeatureApiBean[]> => {
        return httpApi.get({
            url: urls.FEATURES_CESIA_MANAGED,
        });
    };

    enableUiManagedSetting = (featureUid: string): Promise<SimpleMessageResponseDTO> =>
        httpApi.patch({url: urls.FEATURE_ENABLE(featureUid)});

    disableUiManagedSetting = (featureUid: string): Promise<SimpleMessageResponseDTO> =>
        httpApi.patch({url: urls.FEATURE_DISABLE(featureUid)});
}

const missionControlConfigurationService = new MissionControlConfigurationService();
export default missionControlConfigurationService;
