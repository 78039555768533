import {CLUSTERS, KIT_LOCATIONS_ALL, PODS, SERVICE_PROVIDERS, SPONSORS} from '@/constants/commonDataNames';
import {CommonData} from '@/types/commonData';

import COMMON_DATA_MAP from './commonDataMap';

class commonDataService {
    queried: {
        [Property in keyof CommonData]: boolean;
    };

    loaded: {
        [Property in keyof CommonData]: boolean;
    };

    notCached: string[];

    constructor() {
        // Queried common data keys used for cache purpose
        this.queried = {};

        // Loaded common data keys used for cache purpose
        this.loaded = {};

        // List of common data keys that do not need to be cached
        this.notCached = [CLUSTERS, PODS, SERVICE_PROVIDERS, SPONSORS, KIT_LOCATIONS_ALL];
    }

    checkLoad(keys: (keyof CommonData)[]) {
        return keys.filter((el) => !this.queried[el] || !this.loaded[el] || this.notCached.includes(el));
    }

    resetLoad(keys: (keyof CommonData)[]) {
        if (keys && keys.length) {
            keys.forEach((key) => {
                delete this.queried[key];
                delete this.loaded[key];
            });
        }
    }
    clearLoad() {
        this.loaded = {};
        this.queried = {};
    }
    getCommonData = (keys: (keyof CommonData)[]) => {
        keys.forEach((key) => {
            this.queried[key] = true;
        });
        return Promise.all(keys.map((el) => COMMON_DATA_MAP[el]()))
            .then((data: any) => {
                const storeData: CommonData = {};

                keys.forEach((key, index) => {
                    this.loaded[key] = true;
                    storeData[key] = data[index];
                });

                return storeData;
            })
            .catch((err) => {
                keys.forEach((key) => {
                    delete this.queried[key];
                });
                throw err;
            });
    };
}

const commonDataServiceInstance = new commonDataService();
export default commonDataServiceInstance;
