import {
    ActorTypeResponseDTO,
    AppointmentSiteDTO,
    EligibleVendorActorsSearchResponseDTO,
    PatientVendorSearchRequestDTO,
    ReasonOptionDTO,
    SimpleMessageResponseDTO,
    VendorNotAvailableRequestDTO,
    VendorSubstatus,
} from '@/types/gatewayDataModels';

import urls from '../constants/endpoints/gateway';
import httpApi from './httpApi_new';

class VendorsService {
    postAvailableActors = (data: PatientVendorSearchRequestDTO): Promise<EligibleVendorActorsSearchResponseDTO[]> =>
        httpApi.post({
            url: urls.ELIGIBLE_ACTORS,
            data,
        });

    moveToNotAvailable = ({
        siteCode,
        vendorCode,
        actorCode,
        data,
    }: {
        vendorCode: string;
        siteCode: string;
        actorCode: string;
        data: VendorNotAvailableRequestDTO;
    }): Promise<SimpleMessageResponseDTO> =>
        httpApi.post({
            url: urls.MAKE_NOT_AVAILABLE({siteCode, vendorCode, actorCode}),
            data,
        });

    getNotAvailablePartnerReasons = (): Promise<ReasonOptionDTO[]> =>
        httpApi.get({
            url: urls.VENDOR_REASONS_NOT_AVAILABLE_PARTNER,
        });

    getAvailableVendorSiteOptions = (): Promise<AppointmentSiteDTO[]> =>
        httpApi.get({url: urls.GET_AVAILABLE_VENDOR_SITE_OPTIONS});

    getActorsTypes = (): Promise<ActorTypeResponseDTO[]> => httpApi.get({url: urls.ACTOR_TYPES});

    getVendorSubstatuses = (): Promise<VendorSubstatus[]> => httpApi.get({url: urls.VENDOR_SUBSTATUSES});

    changeVendorSubstatus = ({
        vendorId,
        newStatus,
    }: {
        vendorId: number;
        newStatus: string;
    }): Promise<SimpleMessageResponseDTO> =>
        httpApi.patch({
            url: urls.CHANGE_VENDOR_STATUS(vendorId),
            queryParams: {
                subStatus: newStatus,
            },
        });
}

const vendorsService = new VendorsService();
export default vendorsService;
