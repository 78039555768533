import urls from '@/constants/endpoints/gateway';
import {
    ActionQueueCounter,
    AppointmentCancellationServiceRequestResponseDetailDTO,
    AppointmentCancellationServiceRequestResponseOverviewDTO,
    AppointmentModificationRequestCompleteDTO,
    AppointmentModificationRequestDetailsModel,
    CancellationSRRequestModel,
    ChangePartnerRequestDetailsDTO,
    ChangeVendorHandOffCreateDTO,
    ChangeVendorRequestDTO,
    ChangingPartnerReasonDTO,
    CreatedResponseDTO,
    HandOffCancelReasonDTO,
    HandOffCancelRequestDTO,
    HandOffCounters,
    HandOffCreateDTO,
    HandOffOverviewResponseDTO,
    HandOffResponseDTOV1,
    HandOffStatus,
    HandOffUrgency,
    InHomeAppointmentResponseDTO,
    ModificationSchedulingDetailsDTO,
    ModificationSRRequestModel,
    OutstandingDocumentationRequestModel,
    PageAppointmentCancellationServiceRequestResponseDTOV2,
    PageHandOffResponseDTO,
    PostponedNotificationIdResponseDTO,
    PreAppointmentDTO,
    ReasonOptionDTO,
    ReferralDatesRequestDTO,
    SchedulingSRRequestModel,
    SimpleMessageResponseDTO,
    TimeOfDay,
    VendorNotAvailableRequestDTO,
    VendorStatus,
    VendorSubstatus,
} from '@/types/gatewayDataModels';

import httpApi from './httpApi_new';

type CommonHandoffInput = {
    handOffId: number;
    actorCode: string;
    siteCode: string;
    vendorCode: string;
};

class HandOffService {
    getAllHandoffs = ({
        data,
        queryParams,
    }: {
        data: SchedulingSRRequestModel;
        queryParams: {page?: number; size?: number; sort: string | string[]};
    }) =>
        httpApi.post<PageHandOffResponseDTO>({
            url: urls.HAND_OFF,
            queryParams,
            data,
        });

    getAllHandoffsCancellations = ({
        data,
        queryParams,
    }: {
        data: CancellationSRRequestModel;
        queryParams: {page?: number; size?: number; sort?: string | string[]};
    }) =>
        httpApi.post<PageAppointmentCancellationServiceRequestResponseDTOV2>({
            url: urls.HAND_OFF_CANCELLATIONS,
            queryParams,
            data,
        });

    getHandoffById = (id: number) =>
        httpApi.get<PreAppointmentDTO>({
            url: urls.HAND_OFF_BY_ID(id),
        });

    getHandoffOverviewById = (id: number) =>
        httpApi.get<HandOffOverviewResponseDTO>({
            url: urls.HAND_OFF_OVERVIEW_BY_ID(id),
        });

    getHandoffCancellationsOverviewById = (id: number) =>
        httpApi.get<AppointmentCancellationServiceRequestResponseOverviewDTO>({
            url: urls.HAND_OFF_CANCELLATION_OVERVIEW_BY_ID(id),
        });

    moveToNotAvailable = ({
        handOffId,
        vendorCode,
        siteCode,
        actorCode,
        data,
    }: CommonHandoffInput & {data: VendorNotAvailableRequestDTO}) =>
        httpApi.patch<SimpleMessageResponseDTO>({
            url: urls.HAND_OFF_MOVE_TO_NOT_AVAILABLE({handOffId, vendorCode, siteCode, actorCode}),
            data,
        });

    unlockNotAvailable = ({handOffId, vendorCode, siteCode, actorCode}: CommonHandoffInput) =>
        httpApi.patch<SimpleMessageResponseDTO>({
            url: urls.HAND_OFF_UNLOCK_NOT_AVAILABLE({handOffId, vendorCode, siteCode, actorCode}),
        });

    requestIncomplete = ({handOffId, comment}: {handOffId: number; comment: string}) =>
        httpApi.patch<SimpleMessageResponseDTO>({
            url: urls.HAND_OFF_REQUEST_INCOMPLETE(handOffId),
            data: {comment},
        });

    createHandOffRequest = (data: HandOffCreateDTO) =>
        httpApi.post<CreatedResponseDTO>({
            url: urls.CREATE_HAND_OFF,
            data,
        });

    updateHandoffStatus = ({handOffId, status}: {handOffId: number; status: string}) =>
        httpApi.patch<HandOffResponseDTOV1>({
            url: urls.HAND_OFF_STATUS(handOffId),
            queryParams: {
                status,
            },
        });

    cancelHandoff = ({data, handOffId}: {data: HandOffCancelRequestDTO; handOffId: number}) =>
        httpApi.patch<SimpleMessageResponseDTO>({
            url: urls.HAND_OFF_CANCEL(handOffId),
            data,
        });

    getServiceRequestCancelReasons = () => httpApi.get<HandOffCancelReasonDTO[]>({url: urls.HAND_OFF_CANCEL_REASONS});

    getSchedulingCounters = (data: Partial<SchedulingSRRequestModel>) =>
        httpApi.post<HandOffCounters>({
            url: urls.HAND_OFF_COUNTERS_SCHEDULING,
            data,
        });

    getModificationsCounters = (data: Partial<ModificationSRRequestModel>) =>
        httpApi.post<ActionQueueCounter>({
            url: urls.HAND_OFF_COUNTERS_MODIFICATIONS,
            data,
        });

    getVendorChangeCounters = (data: Partial<SchedulingSRRequestModel>) =>
        httpApi.post<ActionQueueCounter>({
            url: urls.HAND_OFF_COUNTERS_VENDOR_CHANGES,
            data,
        });

    getCancellationCounters = (data: Partial<CancellationSRRequestModel>) =>
        httpApi.post<ActionQueueCounter>({
            url: urls.HAND_OFF_COUNTERS_CANCELLATIONS,
            data,
        });

    getOutstandingDocumentationCounters = (data: Partial<OutstandingDocumentationRequestModel>) =>
        httpApi.post<ActionQueueCounter>({
            url: urls.HAND_OFF_COUNTERS_OUTSTANDING_DOCUMENTATION,
            data,
        });

    getHandOffCancellation = (handOffId: number) =>
        httpApi.get<AppointmentCancellationServiceRequestResponseDetailDTO>({
            url: urls.HAND_OFF_CANCELLATION_DETAIL(handOffId),
        });

    cancellationServiceRequest = (handOffId: number, scratchPadValue: string) =>
        httpApi.put<SimpleMessageResponseDTO>({
            url: urls.HAND_OFF_CANCELLATIONS_CONFIRM(handOffId),
            data: {text: scratchPadValue},
        });

    getExistingAppointments = ({handOffId, actorCode, siteCode, vendorCode}: CommonHandoffInput) =>
        httpApi.get<InHomeAppointmentResponseDTO[]>({
            url: urls.HAND_OFF_SUITABLE(handOffId),
            queryParams: {
                actorCode,
                siteCode,
                vendorCode,
            },
        });

    changePartnerHandOff = (data: ChangeVendorHandOffCreateDTO) =>
        httpApi.post<CreatedResponseDTO>({
            url: urls.HAND_OFF_CHANGE_PARTNER,
            data,
        });

    getChangePartnerReasons = () =>
        httpApi.get<ChangingPartnerReasonDTO[]>({
            url: urls.REASONS_CHANGE_PARTNER,
        });

    getNotAvailablePartnerReasons = () =>
        httpApi.get<ReasonOptionDTO[]>({
            url: urls.HAND_OFF_REASONS_NOT_AVAILABLE_PARTNER,
        });

    getHandOffCounters = (data: {
        page?: number;
        size?: number;
        urgencies?: HandOffUrgency[];
        statuses?: HandOffStatus[];
        pods?: number[];
        includeDemo?: boolean;
        sponsors?: string[];
        requestType?: 'SCHEDULING' | 'CHANGE_VENDOR';
        serviceCoordinators?: number[];
        vendorCodes?: string[];
        vendorStatuses?: VendorSubstatus[];
        patientQuery?: string;
        appointmentScheduledFrom?: string;
        appointmentScheduledTo?: string;
        timesOfDay?: TimeOfDay[];
        admission?: boolean;
        discharged?: boolean;
    }) =>
        httpApi.post<HandOffCounters>({
            url: urls.HAND_OFF_COUNTERS,
            data,
        });

    getChangePartnerDetails = (id: number) =>
        httpApi.get<ChangePartnerRequestDetailsDTO>({
            url: urls.HAND_OFF_CHANGE_PARTNER_BY_ID(id),
        });

    changeVendorHandoff = ({requestId, data}: {requestId: number; data: ChangeVendorRequestDTO}) =>
        httpApi.put<SimpleMessageResponseDTO>({
            url: urls.HAND_OFF_CHANGE_PARTNER_BY_ID(requestId),
            data,
        });

    getModificationServiceRequest = ({
        requestId,
        isStartWorking,
        disableGeneralNotify,
    }: {
        requestId: number;
        isStartWorking: boolean;
        disableGeneralNotify?: (value: unknown) => boolean;
    }) =>
        httpApi.get<AppointmentModificationRequestDetailsModel>({
            url: isStartWorking
                ? urls.MODIFICATION_SERVICE_REQUEST_ON_START_WORKING(requestId)
                : urls.MODIFICATION_SERVICE_REQUEST(requestId),
            disableGeneralNotify,
        });

    getVendorModificationServiceRequestIds = (vendorCode: string) =>
        httpApi.get<number[]>({url: urls.VENDOR_MODIFICATION_SERVICE_REQUEST_IDS(vendorCode)});

    getModificationServiceRequestVendorStatuses = () =>
        httpApi.get<VendorStatus[]>({url: urls.MODIFICATION_SERVICE_REQUEST_VENDOR_STATUSES});

    getModificationServiceRequestRefuseReasons = () =>
        httpApi.get<ReasonOptionDTO[]>({url: urls.MODIFICATION_SERVICE_REQUEST_REFUSE_REASONS});

    updateModificationServiceRequestVendorStatus = ({
        requestId,
        vendorStatus,
        activityModificationCount,
        disableGeneralNotify,
    }: {
        requestId: number;
        vendorStatus: string;
        activityModificationCount: number;
        disableGeneralNotify?: (value: unknown) => boolean;
    }) =>
        httpApi.patch<SimpleMessageResponseDTO>({
            url: urls.UPDATE_MODIFICATION_SERVICE_REQUEST_VENDOR_STATUS(requestId),
            queryParams: {
                vendorStatus,
                activityModificationCount,
            },
            disableGeneralNotify,
        });

    updateModificationServiceRequestReferralSentDate = ({
        requestId,
        dateTime,
        activityModificationCount,
        disableGeneralNotify,
    }: {
        requestId: number;
        dateTime: string;
        activityModificationCount: number;
        disableGeneralNotify?: (value: unknown) => boolean;
    }) =>
        httpApi.patch<SimpleMessageResponseDTO>({
            url: urls.UPDATE_MODIFICATION_SERVICE_REQUEST_REFERRAL_SENT_DATE(requestId),
            data: {dateTime},
            queryParams: {
                activityModificationCount,
            },
            disableGeneralNotify,
        });

    updateModificationServiceRequestReferralAcceptedDate = ({
        requestId,
        dateTime,
        activityModificationCount,
        disableGeneralNotify,
    }: {
        requestId: number;
        dateTime: string;
        activityModificationCount: number;
        disableGeneralNotify?: (value: unknown) => boolean;
    }) =>
        httpApi.patch<SimpleMessageResponseDTO>({
            url: urls.UPDATE_MODIFICATION_SERVICE_REQUEST_REFERRAL_ACCEPTED_DATE(requestId),
            data: {dateTime},
            queryParams: {
                activityModificationCount,
            },
            disableGeneralNotify,
        });

    updateModificationServiceRequestReferralDates = ({
        requestId,
        sentDateTime,
        acceptedDateTime,
        activityModificationCount,
        disableGeneralNotify,
    }: ReferralDatesRequestDTO & {
        requestId: number;
        activityModificationCount: number;
        disableGeneralNotify?: (value: unknown) => boolean;
    }) =>
        httpApi.patch<SimpleMessageResponseDTO>({
            url: urls.UPDATE_MODIFICATION_SERVICE_REQUEST_REFERRAL_DATES(requestId),
            data: {
                sentDateTime,
                acceptedDateTime,
            },
            queryParams: {
                activityModificationCount,
            },
            disableGeneralNotify,
        });

    completeModificationServiceRequest = ({
        requestId,
        data,
        activityModificationCount,
        disableGeneralNotify,
    }: {
        requestId: number;
        data: AppointmentModificationRequestCompleteDTO;
        activityModificationCount: number;
        disableGeneralNotify?: (value: unknown) => boolean;
    }) =>
        httpApi.put<PostponedNotificationIdResponseDTO>({
            url: urls.COMPLETE_MODIFICATION_SERVICE_REQUEST(requestId),
            data,
            queryParams: {
                activityModificationCount,
            },
            disableGeneralNotify,
        });

    getModificationSchedulingDetails = (requestId: number) =>
        httpApi.get<ModificationSchedulingDetailsDTO>({
            url: urls.MODIFICATION_SCHEDULING_DETAILS(requestId),
        });
}

const handOffService = new HandOffService();
export default handOffService;
